.l-menu-show{
    position: fixed;
    top: 40%;
    display: block;
    outline: none;
    text-align: left;
    z-index: 1000;
    left:0px;
    cursor: pointer;
    opacity: 1;
    min-height: 120px;

    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
 }
.l-menu-show div.l-menu-content{
   background-color: #81B8E7;
}

.l-menu-show div.l-menu-content .btn{
    padding: 0px;
    border-radius: 0px;
}
.l-menu-show div.l-menu-content .btn-primary{
    background-color: #81B8E7;
    border-color: #81B8E7;
}
.l-menu-show div.l-menu-content a.btn-primary{
    padding: 3px;
}
.l-menu-show div.l-menu-content a.btn-primary:hover{
    background-color: #3276b1;
    border-color: #3276b1;
}
.l-menu-show div.l-menu-content .btn-primary.active{
    background-color: #3276b1;
    border-color: #3276b1;
}

.l-menu-show table{
    min-height: 120px;
}
.l-menu-show div.notification_nav{
    margin-bottom: 10px;
    position: relative;
    left: -95px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.l-menu-show div.notification_nav:hover{
    left: 0px;
}
.l-menu-show div.notification_nav table{
    min-height: 0px;
}
.l-menu-show div.notification_nav table .sm-ico{
    font-size: 14px;
}

.l-menu-show td{
    vertical-align: middle;
}
.l-menu-show i{
    position: relative;
    display: block;
    padding: 5px 5px;
    color: white;
    font-size: 35px;
}

.l-menu-show i.sm-ico{
    font-size: 20px;
}

.left-nav-element{
    display: table;
}


/* General styles for all menus */
 
.cbp-spmenu {
    background: #64A1D6;
    position: fixed;
}
 
.cbp-spmenu h3 {
    color: white;
    padding: 15px;
    margin: 0;
    font-weight: 300;
    background: #428bca;
}
 
.cbp-spmenu div.cbp-search-para {
    display: block;
    color: #fff;
    font-size: 1.1em;
    font-weight: 300;
}

.cbp-spmenu div.cbp-search-para .sm-ico{
    font-size: 15px;
}
 
.cbp-spmenu div.cbp-search-para:hover {
    background: #428bca;
}
 

 
/* Orientation-dependent styles for the content of the menu */
 
.cbp-spmenu-vertical {
    width: 240px;
    height: 100%;
    top: 0;
    z-index: 1000;
}
 
.cbp-spmenu-vertical div.cbp-search-para {
    border-bottom: 1px solid #428bca;
    padding: 1em;
}
 
.cbp-spmenu-horizontal {
    width: 100%;
    height: 150px;
    left: 0;
    z-index: 1000;
    overflow: hidden;
}
 
.cbp-spmenu-horizontal h3 {
    height: 100%;
    width: 20%;
    float: left;
}
 
.cbp-spmenu-horizontal a {
    float: left;
    width: 20%;
    padding: 0.8em;
    border-left: 1px solid #258ecd;
}
 
/* Vertical menu that slides from the left or right */
 
.cbp-spmenu-left {
    left: -240px;
}
 
.cbp-spmenu-right {
    right: -240px;
}
 
.cbp-spmenu-left.cbp-spmenu-open {
    left: 0px;
}
 
.cbp-spmenu-right.cbp-spmenu-open {
    right: 0px;
}
 
/* Horizontal menu that slides from the top or bottom */
 
.cbp-spmenu-top {
    top: -150px;
}
 
.cbp-spmenu-bottom {
    bottom: -150px;
}
 
.cbp-spmenu-top.cbp-spmenu-open {
    top: 0px;
}
 
.cbp-spmenu-bottom.cbp-spmenu-open {
    bottom: 0px;
}



/* Push classes applied to the body */
 
.cbp-spmenu-push {
    overflow-x: hidden;
    position: relative;
    left: 0;
}
 
.cbp-spmenu-push-toright {
    left: 240px;
}
 
.cbp-spmenu-push-toleft {
    left: -240px;
}
 
/* Transitions */
 
.cbp-spmenu,
.cbp-spmenu-push {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
 
/* Example media queries */
 
@media screen and (max-width: 55.1875em){
 
    .cbp-spmenu-horizontal {
        font-size: 75%;
        height: 110px;
    }
 
    .cbp-spmenu-top {
        top: -110px;
    }
 
    .cbp-spmenu-bottom {
        bottom: -110px;
    }
 
}
 
@media screen and (max-height: 26.375em){
 
    .cbp-spmenu-vertical {
        font-size: 90%;
        width: 190px;
    }
 
    .cbp-spmenu-left,
    .cbp-spmenu-push-toleft {
        left: -190px;
    }
 
    .cbp-spmenu-right {
        right: -190px;
    }
 
    .cbp-spmenu-push-toright {
        left: 190px;
    }
}